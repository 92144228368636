<template>
  <div>
    <a-modal
      title="编辑供应商"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-form :form="form" :selfUpdate="true">
          <a-row :gutter="24">
            <a-col :md="12" :sm="24">
              <a-form-item label="供应商名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  placeholder="请输入"
                  v-decorator="['nickname', { rules: [{ required: true, message: '请输入供应商名称！' }] }]"
                />
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="联系人" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  placeholder="请输入"
                  v-decorator="['linkName', { rules: [{ required: true, message: '请输入联系人！' }] }]"
                />
              </a-form-item>
            </a-col>

            <a-col :md="12" :sm="24">
              <a-form-item label="账号" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <div
                  style="cursor: pointer; width: 100%; height: 100%; position: absolute; z-index: 9; left: 0; top: 0"
                  @click="$refs.chooseUser.init()"
                ></div>
                <a-input
                  disabled
                  placeholder="请选择"
                  v-decorator="['userName', { rules: [{ required: true, message: '请选择用户' }] }]"
                >
                  <span slot="suffix"> <a-icon type="right" /></span>
                </a-input>
                <a-input
                  style="visibility: hidden; position: absolute; top: 99999px"
                  disabled
                  placeholder="请选择"
                  v-decorator="['customerId', { rules: [{ required: true, message: '请选择用户！' }] }]"
                >
                  <span slot="suffix"> <a-icon type="right" /></span>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="联系方式" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  placeholder="请输入"
                  v-decorator="['linkMobile', { rules: [{ required: true, message: '请输入手机号！' }] }]"
                />
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="收货地址" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  placeholder="请输入"
                  v-decorator="['address', { rules: [{ required: true, message: '请输入手机号！' }] }]"
                />
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="营业执照" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-upload
                  style="margin: auto"
                  name="avatar"
                  accept=".png,.jpg,jpeg,.gif"
                  list-type="picture-card"
                  :show-upload-list="false"
                  :customRequest="uploadImg"
                  v-decorator="['businessLicense', { rules: [{ required: true, message: '请上传营业执照！' }] }]"
                >
                  <img :src="picture" v-if="picture" style="width: 200px; height: 100px" />
                  <div v-else>
                    <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
    <choose-user ref="chooseUser" />
  </div>
</template>

<script>
import { enterpriseEdit, storeEdit } from '@/api/modular/mallLiving/enterprises'
import chooseUser from './chooseUser.vue'
import COS from 'cos-js-sdk-v5'
import { getUploadScreat } from '@/api/modular/mallLiving/uploadScreat'
export default {
  components: { chooseUser },
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      pictureLoading: false,
      id: '',
      picture: '',
      cos: null,
    }
  },

  methods: {
    setUser(user) {
      console.log('user', user, this.form)

      this.form.setFieldsValue({ userName: user.name })
      this.form.setFieldsValue({ userId: user.id })
    },
    // 初始化方法
    init(record) {
      this.visible = true
      this.id = record.id
      this.picture = record.businessLicense
      this.$nextTick(() => {
        this.form.setFieldsValue({
          nickname: record.nickname,
          linkName: record.linkName,
          mobile: record.mobile,
          linkMobile: record.linkMobile,
          userName: record.customerName,
          customerId: record.customerId,
          address: record.address,
          businessLicense: record.businessLicense,
        })
      })
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          storeEdit({ id: this.id, ...values }).then((res) => {
            if (res.success) {
              this.$message.success('编辑供应商成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
    formatFileName() {
      var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        a = t.length,
        n = ''

      for (var i = 0; i < 10; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n + new Date().getTime()
    },
    //图片上传，cos
    getUploadScreat() {
      let that = this
      getUploadScreat().then((res) => {
        that.uploadOption = res.data
        let data = {
          TmpSecretId: res.data.tmpSecretId,
          TmpSecretKey: res.data.tmpSecretKey,
          XCosSecurityToken: res.data.sessionToken,
          StartTime: res.data.startTime,
          ExpiredTime: res.data.expiredTime,
        }
        sessionStorage.setItem('upload-screat-msg', JSON.stringify(res.data))
        that.cos = new COS({
          getAuthorization: function (options, callback) {
            callback(data)
          },
        })
      })
    },
    //自定义图片上传实现
    uploadImg(filedata) {
      this.pictureLoading = true
      console.log(this.cos)
      console.log(filedata)
      this.upload(filedata).then((res) => {
        console.log('上传', res)
        this.picture = 'https://' + res
        this.form.setFieldsValue({ businessLicense: this.picture })

        this.$nextTick(function () {
          this.$forceUpdate()
        })

        this.pictureLoading = false
      })
    },
    upload(filedata) {
      let that = this
      return new Promise((resolve, reject) => {
        that.cos.putObject(
          {
            // Bucket: 'chanbow-1309518477' /* 必须 */,
            Bucket: 'cos-1311593864',
            Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */,
            Key:
              that.formatFileName() +
              '.' +
              filedata.file.name.split('.')[filedata.file.name.split('.').length - 1] /* 必须 */,
            StorageClass: 'STANDARD',
            Body: filedata.file, // 上传文件对象
            onProgress: (progressData) => {},
          },
          (err, data) => {
            // 将上传后的封面进行路径拼接保存到本地
            if (data.Location) {
              resolve(data.Location)
            } else {
              reject('上传失败')
            }
          }
        )
      })
    },
  },
}
</script>
<style scoped>
/deep/ .ant-input-disabled {
  background: #fff;
}
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
